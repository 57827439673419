import app from "./app";
import {
  Database,
  getDatabase,
  ref,
  set,
  onValue,
  DataSnapshot,
  ListenOptions,
} from "firebase/database";

let _instance = null as Database;

function _checkInstance() {
  if (!_instance) {
    _instance = getDatabase(app);
  }
}
export const obj = {
  get set() {
    _checkInstance();
    return (props: { path: string; value: unknown }) =>
      set(ref(_instance, props?.path), props?.value);
  },
  get onValueChanged() {
    _checkInstance();
    return (props: {
      path: string;
      callback: (snapshot: DataSnapshot) => unknown;
      options?: ListenOptions;
    }) => onValue(ref(_instance, props?.path), props?.callback, props?.options);
  },
};

export default obj;
